import React, { useState, useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';

// Local
import CatalogMenu from './CatalogMenu';

// Utils
import { SiteContext } from 'utils/SiteProvider';

// Components
import ElevationScroll from 'components/ElevationScroll';
import Ability from 'components/Ability';

// Icons
import CloseIcon from '@material-ui/icons/Close';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import HomeIcon from '@material-ui/icons/Home';
import OrderIcon from '@material-ui/icons/ShoppingCart';
import ProductIcon from '@material-ui/icons/ShoppingBasket';
import FAQIcon from '@material-ui/icons/LiveHelp';
import TutorialIcon from '@material-ui/icons/School';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

// Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Typography, { TypographyProps } from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Link from '@material-ui/core/Link';
import ButtonBase from '@material-ui/core/ButtonBase';


interface Props {
	logoPath: string;
	catalog: any[];
	handleClose: Function;
	handleLogout: Function;
};

const DrawerNav: React.FC<Props> = (props) => {
	const classes = useStyles();
	const site:any = useContext(SiteContext);
	const user:any = site?.session?.user?.contact || {};
	const listTextProps:TypographyProps = { variant: 'subtitle2', display: 'block' };

	// State
	const [selected, setSelected] = useState<any|null>(null);
	const [scrollTarget, setScrollTarget] = useState<any>();

	return (<>
		<div className={classes.scroll} ref={node => (node)? setScrollTarget(node) : undefined }>
			<ElevationScroll elevation={3} target={scrollTarget}>
				<AppBar position="sticky" component="div" elevation={0} className={classes.appBar}>
					<Toolbar disableGutters>
						<ButtonBase aria-label="southern glazers logo" component={RouterLink} to="/" className={classes.logo}>
							<img alt="site logo" src={props.logoPath}/>
						</ButtonBase>

						<Typography variant="body1" className={classes.title}>Proof</Typography>
						<div className="flex-grow"></div>
						<IconButton aria-label="close drawer" color="inherit" onClick={() => props.handleClose()}><CloseIcon/></IconButton>
					</Toolbar>
				</AppBar>
			</ElevationScroll>
			{(selected === null) ? (
				<List component="nav" className={classes.menu}>
					<Link to="/" component={RouterLink} underline="none" color="inherit" tabIndex={-1}>
						<ListItem button aria-label="home">
							<ListItemIcon><HomeIcon/></ListItemIcon>
							<ListItemText primary="Home" primaryTypographyProps={listTextProps} />
						</ListItem>
					</Link>

					<Ability subject="Cart" action="create">
						<ListSubheader component="div" disableSticky><Typography variant="subtitle2" color="textSecondary">Shopping</Typography></ListSubheader>
						<Link to="/cart" component={RouterLink} underline="none" color="inherit" tabIndex={-1}>
							<ListItem button aria-label="order">
								<ListItemIcon><OrderIcon/></ListItemIcon>
								<ListItemText primary="Order" primaryTypographyProps={listTextProps} />
							</ListItem>
						</Link>
					</Ability>

					<ListSubheader component="div" disableSticky><Typography variant="subtitle2" color="textSecondary">Products</Typography></ListSubheader>
					
					{props.catalog?.filter((obj:any) => obj.tier === 2).map((item:any) => (
						<ListItem key={item.id} aria-label={item.name} button onClick={() => setSelected(item)}>
							<ListItemIcon><ProductIcon/></ListItemIcon>
							<ListItemText primary={item.name} primaryTypographyProps={listTextProps} />
							<ChevronRightIcon color="action" />
						</ListItem>
					))}

					<ListSubheader component="div" disableSticky><Typography variant="subtitle2" color="textSecondary">Help</Typography></ListSubheader>
					<Link to="/faq" component={RouterLink} underline="none" color="inherit" tabIndex={-1}>
						<ListItem button aria-label="f a q">
							<ListItemIcon><FAQIcon/></ListItemIcon>
							<ListItemText primary="FAQ" primaryTypographyProps={listTextProps} />
						</ListItem>
					</Link>
					<Link to="/tutorial" component={RouterLink} underline="none" color="inherit" tabIndex={-1}>
						<ListItem button aria-label="tutorial">
							<ListItemIcon><TutorialIcon/></ListItemIcon>
							<ListItemText primary="Tutorials" primaryTypographyProps={listTextProps} />
						</ListItem>
					</Link>
				</List>
			):(
				<CatalogMenu
					catalog={props.catalog}
					selected={selected}
					handleSelect={(item:any) => setSelected(item)}
					handleClose={props.handleClose}
				/>
			)}
		</div>
		<Toolbar className={classes.footer} tabIndex={-1}>
			<Avatar className={classes.avatar}>{user?.firstName?.substr(0, 1)}{user?.lastName?.substr(0, 1)}</Avatar>
			<Typography variant="body1" color="textSecondary">{user?.firstName} {user?.lastName}</Typography>
			<div className="flex-grow"></div>
			<IconButton aria-label="sign out" edge="end" onClick={() => props.handleLogout()}><LogoutIcon/></IconButton>
		</Toolbar>
	</>);
};

const useStyles = makeStyles((theme: Theme) => ({
	logo: {
		height: 70,
		padding: theme.spacing(0.5, 0.5, 1, 0.5),
		margin: theme.spacing(0.5),
		borderRadius: theme.shape.borderRadius,
		'& > img': {
			display: 'block',
			height: '100%',
			objectFit: 'contain',
		},
		'&:focus': {
			backgroundColor: 'rgba(255, 255, 255, 0.35)',
		},
	},
	title: {
		lineHeight: 1.2,
		fontSize: '1.1rem',
	},
	appBar: {
		overflow: 'hidden',
		'&.MuiAppBar-colorPrimary': {
		},
	},
	scroll: {
		flex: '1 0 0px',
		overflowY: 'auto',
		paddingBottom: theme.spacing(20),
	},
	menu: {
		flex: '1 0 0px',
		'& > .MuiListSubheader-root': {
			lineHeight: 'initial',
			marginTop: theme.spacing(1),
		},
		'& > .MuiListItem-root > .MuiListItemIcon-root': {
			minWidth: 40,
		},
		'& > .MuiLink-root > .MuiListItem-root > .MuiListItemIcon-root': {
			minWidth: 40,
		},
		'& > .MuiListItem-container > .MuiListItem-root > .MuiListItemText-root > .MuiTypography-body1': {
			marginBottom: theme.spacing(-0.5),
		},
	},
	footer: {
		borderTop: `1px solid ${theme.palette.divider}`,
		backgroundColor: 'white',
	},
	avatar: {
		margin: theme.spacing(0, 1, 0, -1),
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.secondary.contrastText,
	},
	nested: {
		paddingLeft: theme.spacing(4),
	},
}));

export default DrawerNav;
